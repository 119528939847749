import { Component, Input } from '@angular/core';
import { Card } from '@core/interfaces/card.interface';

@Component({
  selector: 'app-cards-dashboard',
  templateUrl: './cards-dashboard.component.html',
  styleUrls: ['./cards-dashboard.component.scss'],
})
export class CardsDashboardComponent {
  @Input() public cards: Card[];

  public onClick(card: Card): void {
    if (card.hasOwnProperty('callback')) {
      card.callback(card);
    }
  }
}
