<div class="dialog-layout-skeleton" [hidden]="!loading && isFormConfig">
  <ng-content select="[skeleton]"></ng-content>
</div>

<section class="dialog-layout--container" [hidden]="loading">
  <div class="dialog-layout--content">
    <ng-content select="[content]"></ng-content>
  </div>

  <div *ngIf="showFooter" class="dialog-layout--footer">
    <div class="buttons">
      <ng-content select="[buttons]"></ng-content>
    </div>
  </div>
</section>
