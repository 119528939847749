<div class="page-layout">
  <div class="page-layout-header">
    <div class="page-layout-title">
      <h1 class="page-layout-title-text">{{ title }}</h1>

      <ng-content select="[title]"></ng-content>
    </div>

    <div class="page-layout-actions">
      <ng-content select="[actions]"></ng-content>
    </div>
  </div>

  <div class="page-layout-content">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
