<div class="station-section">
  <div class="station-section-content">
    <div class="row card-row">
      <ng-container *ngFor="let card of cards">
        <div class="card-col col-12 col-md-6 col-lg-4 col-xl-4">
          <div class="station-card" (click)="onClick(card)">
            <i *ngIf="card?.icon" class="icon" [class]="card.icon"></i>

            <div *ngIf="card?.title" class="title">
              {{ card.title }}
            </div>

            <div *ngIf="card?.description" class="description my-3">
              {{ card.description }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
