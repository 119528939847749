import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cssUrl',
})
export class CssUrlPipe implements PipeTransform {
  public transform(path: string): string {
    return path ? `url(${path})` : path;
  }
}
