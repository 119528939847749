import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CapturumButtonModule } from '@capturum/ui/button';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CardsDashboardComponent } from '@shared/components/cards-dashboard/cards-dashboard.component';
import { CssUrlPipe } from '@shared/pipes/css-url.pipe';
import { SetOccupationPopupComponent } from '@shared/components/set-occupation-popup/set-occupation-popup.component';
import { CapturumInputModule } from '@capturum/ui/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { TableModule } from 'primeng/table';
import { SumByFieldPipe } from '@shared/pipes/sum-by-field.pipe';
import { CapturumInputNumberModule } from '@capturum/ui/input-number';
import { CapturumSkeletonModule } from '@capturum/ui/skeleton';
import { DialogLayoutComponent } from '@shared/components/dialog-layout/dialog-layout.component';
import { PageLayoutComponent } from '@shared/components/page-layout/page-layout.component';

const BASE_PIPES = [CssUrlPipe, SumByFieldPipe];

@NgModule({
  declarations: [CardsDashboardComponent, SetOccupationPopupComponent, ...BASE_PIPES],
  imports: [
    CommonModule,
    NgxPermissionsModule,
    CapturumButtonModule,
    TranslateModule,
    CapturumSharedModule,
    CapturumInputModule,
    ReactiveFormsModule,
    CapturumInfoTableModule,
    TableModule,
    FormsModule,
    CapturumInputNumberModule,
    CapturumSkeletonModule,
    DialogLayoutComponent,
    PageLayoutComponent,
  ],
  exports: [
    ...BASE_PIPES,
    NgxPermissionsModule,
    TranslateModule,
    CapturumSharedModule,
    CardsDashboardComponent,
    DialogLayoutComponent,
    PageLayoutComponent,
  ],
})
export class SharedModule {}
