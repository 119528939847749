@import 'abstracts';

.page-layout {
  .page-layout-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 15px;
    padding-top: 16px;

    @include media-breakpoint-down(md) {
      display: block;
    }

    .page-layout-actions {
      display: flex;
      margin-top: 0.94rem;
      @include media-breakpoint-up(sm) {
        margin-top: 0;
      }

      & > *:not(:last-child) {
        margin-right: 10px;
      }
    }

    .page-layout-title {
      display: flex;

      .page-layout-title-text {
        font-size: 1.4rem;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
